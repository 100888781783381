import Collection from '../collections/Collection';

export default class AspectsCollection extends Collection {
    filterByView(view)
    {
        const aspects = this.constructor();

        switch (view) {
            case 'selected':
                this.items.forEach((aspect) => {
                    if (aspect.isChosen) {
                        aspects.push(aspect);
                    }
                }); 

                return aspects; 

        }

        return this;
    }
}