export default class Collection {
    items = [];
 
    constructor(items) 
    {
      if (Array.isArray(items)) {
        this.items = items;
      }
    }

    push(item) {
      this.items.push(item);

      return this;
    }

    all()
    {
      return this.items;
    }
}