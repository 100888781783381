<template>
    <div>
        <Header/>

        <div class="main-wrapper">

            <aside>
                <ul class="list-unstyled sidebar-dashboard">
                    <li>
                        <router-link to="/"><span><icon icon="dashboard"/></span>
                            <span>Dashboard</span>
                        </router-link>
                    </li>
                    <li class="seperator"></li>
                    <li>
                        <router-link to="/analyse"><span><icon icon="analyse"/></span>
                            <span>Analyse</span>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/interventies">
                            <span><icon icon="search"/></span><span>Acties</span>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/werkplan">
                            <span><icon icon="workplan"/></span><span>Werkplan</span>
                        </router-link>
                    </li>
                    <li class="seperator"></li>
                    <li>
                        <router-link to="/kennisbank">
                            <span><icon icon="knowledgebase"/></span><span>Kennisbank</span>
                        </router-link>
                    </li>
                </ul>
            </aside>

            <main>
                <div class="main__breadcrumbs">
                    <Breadcrumb/>
                </div>
                <div class="main__content">
                    <router-view :key="$route.fullPath"/>
                </div>
                <div class="main__footer">
                    <Footer/>
                </div>
            </main>

            <div class="shape shape--top">
                <div class="shape__inner">
                    <img src="@/assets/img/top-pink-shape.svg" alt="">
                </div>
            </div>

            <div class="shape shape--bottom">
                <div class="shape__inner">
                    <img src="@/assets/img/bottom-blue-shape.svg" alt="">
                </div>
            </div>

        </div>
        <!-- <div class="modal-backdrop fade"></div> -->

        <roleModal></roleModal>
    </div>
</template>

<script>
import '@/assets/scss/app.scss';
import '@/assets/scss/print.scss';
import RoleModal from "@/components/RoleModal";
import Header from "./views/layout/Header";
import Footer from "./views/layout/Footer";
import Breadcrumb from "./views/layout/Breadcrumb";
import Icon from "./components/Icon";

export default {
    name: 'Leeromgeving onderzoekscultuur opleidingen',
    components: {Header, Footer, Breadcrumb, Icon, RoleModal},
    beforeCreate() {
        this.$store.commit('initialiseStore');
    },
    mounted() {
        //Fetch by default
        this.$store.dispatch('fetchThemes');
        this.$store.dispatch('fetchRoles');

        // Persistant storage
        this.$store.subscribe((mutation, state) => {
            const store = {
                userRole: state.userRole,
                themes: state.themes,
                aspects: state.aspects,
            };

            window.localStorage.setItem('store', JSON.stringify(store));
        });
    },
    watch: {
        $route() {
            this.$store.commit('CLEAR_BREADCRUMBS');
        }
    }
}
</script>
