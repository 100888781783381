<template>
    <div class="breadcrumbs breadcrumbs-wrapper">

        <div class="breadcrumbs-list">
            <div class="breadcrumb-item" v-for="breadcrumb in breadcrumbs" :key="breadcrumb" v-on:click="onClick(breadcrumb)" v-bind:class="{ 'cursor-pointer': isClickable(breadcrumb) }">{{ breadcrumb.label}}</div>
        </div>

        <div class="breadcrumbs-buttons">
            <printButton v-if="breadcrumbsButtons.print"  />
        </div>
    </div>
</template>

<script>
    import PrintButton from "@/components/PrintButton";

    export default {
        name: 'Home',
        components: {
            PrintButton
        },
        computed: {
            breadcrumbs() {
                return this.$store.state.breadcrumbs;
            },
            breadcrumbsButtons() {
                return this.$store.state.breadcrumbs_buttons;
            }
        },
        methods: {
            isClickable(breadcrumb) {
                if (typeof breadcrumb.path !== 'undefined') {
                    return true;
                }
                return false;
            },
            onClick(breadcrumb) {
                if (typeof breadcrumb.path !== 'undefined') {
                    this.$router.push({ path: breadcrumb.path });
                }
            },
        }
    }
</script>