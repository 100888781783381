import {createRouter, createWebHashHistory} from 'vue-router'
import Dashboard from '../views/Dashboard.vue'
import { trackRouter } from "vue-gtag-next";

const routes = [
    {
        path: '/',
        name: 'Dashboard',
        component: Dashboard
    },
    {
        path: '/over/',
        name: 'Over',
        component: () => import(/* webpackChunkName: "over" */ '../views/Page.vue'),
        props: {
            page_id: 5561
        }
    },
    {
        path: '/disclaimer/',
        name: 'Disclaimer',
        component: () => import(/* webpackChunkName: "disclaimer" */ '../views/Page.vue'),
        props: {
            page_id: 5329
        }
    },
    {
        path: '/privacy/',
        name: 'Privacy',
        component: () => import(/* webpackChunkName: "privacy" */ '../views/Page.vue'),
        props: {
            page_id: 5232
        }
    },
    {
        path: '/analyse',
        name: 'Analyse',
        component: () => import(/* webpackChunkName: "analyse" */ '../views/Analyse.vue'),
        children: [
            {
                path: '',
                component: () => import(/* webpackChunkName: "analyse-home" */ '../views/analyse/AnalyseHome.vue'),
            },
            {
                path: ':thema',
                component: () => import(/* webpackChunkName: "analyse-thema" */ '../views/analyse/AnalyseThema.vue'),
            },
            {
                name: 'analyse-scan',
                path: ':thema/scan',
                component: () => import(/* webpackChunkName: "analyse-scan" */ '../views/analyse/AnalyseScan.vue'),
            },
            {
                name: 'analyse-scan2',
                path: ':thema/scan/stap-2',
                component: () => import(/* webpackChunkName: "analyse-scan2" */ '../views/analyse/AnalyseScan_Stap2.vue'),
            },
        ]
    },
    {
        path: '/interventies',
        name: 'Interventies',
        component: () => import(/* webpackChunkName: "interventies" */ '../views/Interventies.vue'),
        children: [
            {
                path: '',
                component: () => import(/* webpackChunkName: "interventies-home" */ '../views/interventies/InterventiesHome.vue'),
            },
            {
                path: ':thema',
                name: 'interventie-thema',
                component: () => import(/* webpackChunkName: "interventies-thema" */ '../views/interventies/InterventiesThema.vue'),
            },
            {
                path: ':thema/:aspect/:interventie',
                name: 'interventie-thema-detail',
                component: () => import(/* webpackChunkName: "interventies-detail" */ '../views/interventies/InterventiesDetail.vue'),
            },
        ]
    },
    {
        path: '/werkplan',
        name: 'Werkplan',
        component: () => import(/* webpackChunkName: "werkplan" */ '../views/Werkplan.vue')
    },
    {
        path: '/kennisbank',
        name: 'Kennisbank',
        component: () => import(/* webpackChunkName: "kennisbank" */ '../views/Kennisbank.vue'),
        children: [
            {
                path: '',
                component: () => import(/* webpackChunkName: "kennisbank-home" */ '../views/kennisbank/KennisbankHome.vue'),
            },
            {
                path: ':thema',
                name: 'kennisbank-thema',
                component: () => import(/* webpackChunkName: "kennisbank-thema" */ '../views/kennisbank/KennisbankThema.vue'),
            }
        ]
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes
});

trackRouter(router);

export default router
